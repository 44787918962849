import log from 'loglevel';

// The Global Logger for app-level logging
if (process.env.NODE_ENV === 'development') {
    log.setLevel('debug'); // will show "debug", "info", "warn", "error" messages
} else if (process.env.NODE_ENV === 'production') {
    log.setLevel('warn'); // will show "warn", "error" messages
} else {
    log.setLevel('info'); // will show "info", "warn", "error" messages if the environment is not set
}

// The Dedicated Logger for module-level logging
const createModuleLogger = (moduleName) => {
    const moduleLogger = log.getLogger(moduleName);
    moduleLogger.setLevel(log.getLevel());
    return moduleLogger;
};

// Log formatter
const originalMethodFactory = log.methodFactory;

log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalMethodFactory(methodName, logLevel, loggerName);
    return (rawData, ...args) => {
        const prefix = `[${methodName.toUpperCase()}] [${loggerName || 'Global'}] `;
        rawMethod(prefix, rawData, ...args);
    };
};

// Apply modified methodFactory
log.setLevel(log.getLevel());

export const logger = log; // Default logger
export { createModuleLogger }; // Dedicated logger for a specific module
