import {Col, Row, Tabs, Space, Table, Tag, Button} from 'antd';
import {Parser} from '@json2csv/plainjs';
import React, {useEffect, useState, useContext, useMemo} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {SmileOutlined, FrownOutlined, CheckOutlined} from "@ant-design/icons";
import {useAtom, atom} from "jotai";

import {atomGlobalExperiment, atomGlobalSpectrum, atomWikiID} from "../../../Data/Atoms";
import Plot from 'react-plotly.js';



export default ({plotData}) => {
    const navigate = useNavigate();
    const [atomWikiId] = useAtom(atomWikiID);
    const [statePlotlyData, setPlotlyData] = useState({});

    const onPlotClick = (e) => {
        const curData = e.points[0].data
        const data = curData.wiki_id[e.points[0].pointIndex];
        // console.log(curData, e, data)
        navigate(`/${data}`, {
            replace: true,
            state: "from_plot"
        });

    }

    useEffect(() => {
        if (plotData) {
            // console.log(plotData)
            // Generate Highlighted Plot
            const plotlyHighlightData = plotData.filter((d, i) => d.wiki_id === `${atomWikiId.id1}/${atomWikiId.id2}`);

            // Generate plot data
            const plotDataKnown = plotData.filter((d, i) => d["name"]);
            const plotDataUnknown = plotData.filter((d, i) => !d["name"]);
            const plotlyData = [
                {
                    x: plotDataUnknown.map(d => d.rt),
                    y: plotDataUnknown.map(d => d.precursor_mz),
                    wiki_id: plotDataUnknown.map(d => d.wiki_id),
                    mode: 'markers',
                    type: 'scatter',
                    color: '#1f77b4',
                    text: plotDataUnknown.map(d => `Precursor m/z: ${d.precursor_mz}\nRT: ${d.rt}`),
                    marker: {size: 4, alpha: 0.5},
                },
                {
                    x: plotDataKnown.map(d => d.rt),
                    y: plotDataKnown.map(d => d.precursor_mz),
                    wiki_id: plotDataKnown.map(d => d.wiki_id),
                    mode: 'markers',
                    type: 'scatter',
                    color: '#ff7f0e',
                    text: plotDataKnown.map(d => `Precursor m/z: ${d.precursor_mz}\nRT: ${d.rt}`),
                    marker: {size: 4, alpha: 0.5},
                }, {
                    x: plotlyHighlightData.map(d => d.rt),
                    y: plotlyHighlightData.map(d => d.precursor_mz),
                    wiki_id: plotlyHighlightData.map(d => d.wiki_id),
                    mode: 'markers',
                    type: 'scatter',
                    text: plotlyHighlightData.map(d => `Precursor m/z: ${d.precursor_mz}\nRT: ${d.rt}`),
                    color: '#a02c2c',
                    marker: {size: 10, alpha: 1.0},
                }
            ]

            const plotlyLayout = {
                xaxis: {
                    range: [0, Math.max(plotData.map(d => d.rt)) * 1.1],
                    title: "Retention time",
                },
                yaxis: {
                    range: [0, Math.max(plotData.map(d => d.precursor_mz)) * 1.1],
                    title: "Precursor m/z",
                },
                showlegend: false,
                autosize: true,
                hovermode: "closest",
                margin: {l: 55, r: 10, b: 30, t: 10, pad: 0},
            }
            setPlotlyData({
                data: plotlyData,
                layout: plotlyLayout,
            })
        }
    }, [plotData, atomWikiId.id2])

    return <Plot
        style={{position: 'relative', width: '100%', height: '25em'}}
        useResizeHandler={true}
        onClick={onPlotClick}
        data={statePlotlyData.data} layout={statePlotlyData.layout} config={{}}/>
}

