import React, {useEffect, useState} from "react";
import {Col, Checkbox, Radio, Row, Space, Typography, Select, Button, Modal, Form, Input} from "antd";
import {atom, useAtom} from "jotai";

export const atomSampleInfo = atom({charge: null});


export default () => {
    const [stateSampleInfo, setSampleInfo] = useAtom(atomSampleInfo);

    return <Space direction={"vertical"} style={{width: "100%"}}>
        <Space>
            <Typography.Text>Charge:</Typography.Text>
            <Select
                style={{width: "15em"}}
                defaultValue={stateSampleInfo.charge}
                onChange={(value) => {
                    setSampleInfo({...stateSampleInfo, charge: value})
                }}
                options={[{value: 1, label: "Positive"}, {value: -1, label: "Negative"}]}
            />
        </Space>
    </Space>
}