import React, {Suspense, useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Row, Skeleton} from "antd";
import {useAtom} from 'jotai'

import {atomGlobalExperiment, atomWebsiteMode, SPECTRA_TYPE, PERMISSION_TYPE, atomWikiID} from "../../Data/Atoms";
import AllData from "../../Data/AllData";
import UserInfo from "../User/UserInfo";

const TemplateWebsite = (props) => {
    const [stateWebsiteMode, ] = useAtom(atomWebsiteMode)
    let navigate = useNavigate();
    return (<>
        <AllData/>
        <Row justify="space-between" align={"middle"} style={{
            background: "#fff",
            boxShadow: "0 2px 8px rgba(0,0,0,.11)"
        }}>
            <div>
                <a href="/"
                   style={{
                       cursor: "pointer",
                       lineHeight: 1.5,
                       color: "#5f9bf1",
                       paddingLeft: "8px",
                       marginBottom: 0,
                       fontSize: "2.2rem",
                   }}>Mass.Wiki</a>
                {
                    stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL ? <>
                        internal version</> : <>beta version</>
                }
            </div>
            <div style={{paddingRight: "2em"}}>
                <UserInfo/>
            </div>
        </Row>
        <div style={{paddingTop: "8px"}}/>
        <Suspense fallback={<div>Loading...<Skeleton active/></div>}>
            {props.children}
        </Suspense>
    </>);
}
export default TemplateWebsite;