import React, {useState} from "react";
import {Col, Spin, Radio, Row, Space, Typography, Select, Button, Modal, Form, Input} from "antd";
import {atom, useAtom} from "jotai";
import {useEasyGet} from "../Global/Message";
import {urlMassWiki} from "../../Config/MassWikiUrl";

import { createModuleLogger } from "../../Library/logger";
const logger = createModuleLogger('UploadMethod');

export const atomMethod = atom({
    type: null, // Can be "none", "existed"
    id: null
});
const atomShowModal = atom(false);

const ModalForNewMethod = () => {
    const [stateChromMethod, setChromMethod] = useAtom(atomMethod);
    const [stateShowModal, setShowModal] = useAtom(atomShowModal);
    const [form] = Form.useForm();

    const [getRequest, messageContextHolder] = useEasyGet(
        urlMassWiki.method.createMethod,
        () => {
        },
        (data) => setChromMethod({
            ...stateChromMethod,
            wikiId: data.wiki_id,
            name: data.name,
            description: data.description,
        }),
        "chromMethod.createMethod", "Creating new chromatography method..."
    )

    const handleCancel = () => {
        setShowModal(false)
    }
    const handleOk = () => {
        const submitData = form.getFieldsValue();
        // console.log("form", submitData)
        form.submit();
    }
    const formSubmitSuccess = (values) => {
        // console.log('Success:', values)
        const submitData = form.getFieldsValue();
        getRequest.run(submitData.methodName, submitData.description)
        setShowModal(false)
    }
    const formSubmitFailed = (errorInfo) => {
        logger.error('Failed:', errorInfo)
    }
    return <>
        {messageContextHolder}
        <Modal title="Create new chromatography method" open={stateShowModal} width={650}
               destroyOnClose={true} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} name={"newMethod"} labelCol={{span: 6}} wrapperCol={{span: 18}} style={{marginTop: "2em"}}
                  onFinish={formSubmitSuccess}
                  onFinishFailed={formSubmitFailed}
            >
                <Form.Item label="Method Name" name="methodName"
                           rules={[{required: true, message: 'Please input method name!'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Description" name="description"
                           rules={[{required: false, message: 'Please input method description!'}]}>
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

const SelectMethod = () => {
    const [, setMethod] = useAtom(atomMethod);
    const [stateDisplayNameList, setDisplayNameList] = useState([]);

    const [getRequest, messageContextHolder] = useEasyGet(
        urlMassWiki.method.getAllMethod,
        () => setDisplayNameList([]),
        (data) => setDisplayNameList(
            data.map((d) => ({value: d.id, label: d.name}))
        ),
        "chromMethod.get_all_methods", "Getting all available methods..."
    )

    const onChange = (value) => {
        setMethod({type: "existed", id: value});
    };

    const onFocus = () => {
        getRequest.run();
    }

    return <Row>
        {messageContextHolder}
        <Col span={2}/>
        <Col span={22} style={{width: "100%", display: "flex"}}>
            <div style={{width: "100%", display: "flex", alignItems: "center"}}>
                {getRequest.loading ? <>
                        <Spin/>
                        <Typography.Text style={{whiteSpace: "nowrap", marginRight: "1em"}}>
                            Loading chromatography methods...
                        </Typography.Text></>
                    : <Typography.Text style={{whiteSpace: "nowrap", marginRight: "1em"}}>
                        Chromatography Method:
                    </Typography.Text>
                }
                <Select
                    style={{width: "100%", minWidth: "10em", display: "inline-block"}}
                    showSearch
                    placeholder="Select a chromatography method"
                    optionFilterProp="label"
                    onChange={onChange}
                    onFocus={onFocus}
                    options={stateDisplayNameList}
                />
            </div>
        </Col>
    </Row>


}

const UploadMethod = () => {
    const [stateMethod, setMethod] = useAtom(atomMethod);
    return <div align={"left"}>
        {/*<Divider orientation="center">Chromatography Method</Divider>*/}
        <Space direction="vertical" style={{width: "100%"}}>
            <Radio value={"none"} onChange={(e) => setMethod({type: "none", id: null})}
                   checked={stateMethod.type === "none"}>
                This study do not use common chromatography methods. </Radio>
            <Radio value={"existed"} onChange={(e) => setMethod({type: "existed", id: null})}
                   checked={stateMethod.type === "existed"}>This study use an existed chromatography method in
                MassWiki, MassWiki will use this method for retention time alignment
                .</Radio>
            {
                stateMethod.type === "existed" ? <SelectMethod/> : null
            }
        </Space>
    </div>
}

export default UploadMethod;
