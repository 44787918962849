import {Button, Checkbox, Col, Input, Row, Space, Tabs} from 'antd';
import {Parser} from '@json2csv/plainjs';
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import {useAtom} from "jotai";

import {atomGlobalExperiment, atomWikiID} from "../../../Data/Atoms";
import {atomSpectralListTableData, SpectralListTableData} from "./SpectralListTableData";
import VirtualTable from "../../../Library/VirtualTable";
import SpectralListPlot from "./SpectralListPlot";

import {createModuleLogger} from '../../../Library/logger';

const logger = createModuleLogger('SpectralListTable');

const sortString = (a, b, key) => (a[key] ? a[key] : "").localeCompare(b[key] ? b[key] : "")
const sortNumber = (a, b, key) => (a[key] ? a[key] : 0.) - (b[key] ? b[key] : 0.)
const numberToFixed = (value, defaultValue, digits) => (value === undefined ? defaultValue : value.toFixed(digits))

const getColumn = (stateGlobalAnalysisData) => {
        let columns = []
        let columnsTemplate = [{
            title: "RT",
            dataIndex: "rt",
            render: (_, record) => numberToFixed(record.rt, 0.0, 2),
            sorter: (a, b) => sortNumber(a, b, "rt"),
            width: 60,
        }, {
            title: "Precursor m/z",
            dataIndex: "precursor_mz",
            render: (_, record) => numberToFixed(record.precursor_mz, 0.0, 3),
            sorter: (a, b) => sortNumber(a, b, "precursor_mz"),
            width: 80,
        }, {
            title: "Entropy",
            dataIndex: "entropy",
            render: (_, record) => numberToFixed(record.entropy, 0.0, 1),
            sorter: (a, b) => sortNumber(a, b, "entropy"),
            width: 40,
        }, {
            title: "Name",
            dataIndex: "name",
            width: 150,
            sorter: (a, b) => sortString(a, b, "name"),
        }, {
            title: "Adduct",
            dataIndex: "adduct",
            width: 40,
            sorter: (a, b) => sortString(a, b, "adduct"),
        }, {
            title: "Identity search score",
            dataIndex: "identity_score",
            defaultSortOrder: 'descend',
            render: (_, record) => numberToFixed(record["identity_score"], "-", 3),
            sorter: (a, b) => sortNumber(a, b, "identity_score"),
            width: 40,
        }, {
            title: "Fuzzy search score",
            dataIndex: "fuzzy_score",
            render: (_, record) => numberToFixed(record["fuzzy_score"], "-", 3),
            sorter: (a, b) => sortNumber(a, b, "fuzzy_score"),
            width: 40,
        }]
        // If binbase exists in the metadata, add the binbase column
        if ((stateGlobalAnalysisData.metadata || {}).binbase) {
            columns = [{
                title: "",
                key: "idx",
                render: (text, record, index) => `${index + 1}`,
                width: 30,
            }, ...columnsTemplate, {
                title: "BinBase",
                dataIndex: "raw_splash",
                render: (_, record) => <a
                    href={`http://binview.metabolomics.us.s3-website-us-west-2.amazonaws.com/binview/compound/${stateGlobalAnalysisData.metadata.binbase.method}/${stateGlobalAnalysisData.metadata.binbase.version}/${record["raw_splash"]}`}
                    target="_blank">Link</a>,
                width: 60,
            }]
        } else {
            columns = [{
                title: "",
                key: "idx",
                render: (text, record, index) => `${index + 1}`,
                width: 30,
            }, {
                title: "Scan",
                dataIndex: "scan",
                render: (text, record, index) => `${record.scan}`,
                sorter: (a, b) => (a["scan"] ? a["scan"] : 0.) - (b["scan"] ? b["scan"] : 0.),
                width: 30,
            }, ...columnsTemplate, {
                title: "Annotation method",
                dataIndex: "annotation_method",
                defaultSortOrder: 'descend',
                width: 80,
            }, {
                title: "Peaks source",
                dataIndex: "peaks_source",
                width: 80,
                sorter: (a, b) => (a["peaks_source"] ? a["peaks_source"] : "").localeCompare(b["peaks_source"] ? b["peaks_source"] : ""),
            }]
        }

        columns.map(k => ({
            key: k.dataIndex,
            ...k
        }));
        return columns;
    }

const SpectralListTable = () => {
    const navigate = useNavigate();
    const [stateWikiId] = useAtom(atomWikiID);
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);

    const [stateHighlightRow, setStateHighlightRow] = useState({row: 0, version: 0});
    const [stateTableSorter, setTableSorter] = useState({field: "identity_score", order: "descend"});

    const [stateSpectralListTableData,] = useAtom(atomSpectralListTableData);
    // logger.warn(stateSpectralListTableData);
    // /////////////////////////////////////////////////////////////////
    // // Determine the display mode
    // const [stateDisplayMode, setDisplayMode] = useState("user");
    // useEffect(() => {
    //     if ((stateGlobalExperiment.metadata || {}).source === "binbase") {
    //         logger.info("Setting display mode to binbase")
    //         setDisplayMode("binbase")
    //     } else {
    //         setDisplayMode("user")
    //     }
    // }, [stateGlobalExperiment])
    //
    // /////////////////////////////////////////////////////////////////
    // // Update table data
    // useEffect(() => {
    //     setTableData(stateGlobalExperiment.spectra);
    // }, [stateGlobalExperiment, stateDisplayMode]);

    /////////////////////////////////////////////////////////////////
    // For exporting the table data
    const [stateTextFile, setStateTextFile] = useState(null);
    useEffect(() => {
        if (stateSpectralListTableData && stateSpectralListTableData.length > 0) {
            const keyOutput = Object.keys(stateSpectralListTableData[0]).filter(k => k[0] != "_");
            // console.log(keyOutput);
            const stateDataForExport = stateSpectralListTableData.map(d => Object.fromEntries(Object.keys(d).filter(k => k[0] != "_").map(k => [k, d[k]])));
            // console.log(stateDataForExport);
            const parser = new Parser();
            const csv = parser.parse(stateDataForExport);
            const data = new Blob([csv], {type: 'text/plain'});
            if (stateTextFile !== null) {
                window.URL.revokeObjectURL(stateTextFile);
            }
            const textFile = window.URL.createObjectURL(data);
            setStateTextFile(textFile);
        }
    }, [stateSpectralListTableData]);

    /////////////////////////////////////////////////////////////////////////
    // For displaying the empty spectra
    const [stateShowEmptySpectra, setShowEmptySpectra] = useState(true);
    const [stateSearchText, setSearchText] = useState("");

    /////////////////////////////////////////////////////////////////
    // For displaying the extra buttons
    const tabExtra = <>
        <Space>
            <Input prefix={<SearchOutlined/>} placeholder="Searching"
                   onChange={(e) => setSearchText(e.target.value)}
                   value={stateSearchText}/>
            <Checkbox checked={stateShowEmptySpectra} onChange={(e) => setShowEmptySpectra(e.target.checked)}>
                Show spectra without MS/MS peaks
            </Checkbox>
            {
                stateTextFile ? <>
                    <Button href={stateTextFile} download="masswiki_result.csv"
                            style={{marginRight: "8px"}}>Export
                        results</Button>
                </> : <></>
            }
        </Space>
    </>

    const [stateDisplayTableData, setDisplayTableData] = useState([]);
    useEffect(() => {
        let displayTableData = stateSpectralListTableData.filter(d => stateShowEmptySpectra || d["peak_number"] > 0);
        if (stateSearchText) {
            const searchText = stateSearchText.toLowerCase();
            displayTableData = displayTableData.filter(d => {
                if (d["name"] && d["name"].toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["adduct"] && d["adduct"].toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["rt"] && d["rt"].toString().toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["precursor_mz"] && d["precursor_mz"].toString().toLowerCase().includes(searchText)) {
                    return true;
                }
            });
        }
        setDisplayTableData(displayTableData);
    }, [stateSpectralListTableData, stateShowEmptySpectra, stateSearchText]);

    /////////////////////////////////////////////////////////////////
    const columns = getColumn(stateGlobalExperiment);
    // Jump to the selected row
    const jumpToRow = () => {
        const tableData = [...stateDisplayTableData];
        // Get currentColumn from columns
        let currentColumn = {};
        if (stateTableSorter.column) {
            currentColumn = stateTableSorter.column;
        } else {
            currentColumn = columns.find(c => c.dataIndex === stateTableSorter.field);
        }
        // logger.warn("Jumping to row", stateWikiId.id2, tableData, stateTableSorter, currentColumn)
        if (stateTableSorter.order === "ascend") {
            // Sort table data
            tableData.sort((a, b) => currentColumn.sorter(a, b));
        } else if (stateTableSorter.order === "descend") {
            // Sort table data
            tableData.sort((a, b) => currentColumn.sorter(b, a));
        }
        const row_id = tableData.findIndex(d => d.wiki_id.toString() === `${stateWikiId.id1}/${stateWikiId.id2}`);
        setStateHighlightRow({row: row_id, version: stateHighlightRow.version + 1});
    }
    useEffect(() => {
        jumpToRow();
    }, [stateTableSorter]);
    let location = useLocation();
    useEffect(() => {
        if (stateWikiId.id2 && (location.state !== "from_click" || !location.state) && stateDisplayTableData.length > 0) {
            jumpToRow();
        }
    }, [stateWikiId.id2, stateDisplayTableData])

    /////////////////////////////////////////////////////////////////
    // For displaying the table
    const tabItems = [
        {
            key: 'list',
            label: 'List',
            children: <> <VirtualTable
                scrollToRow={stateHighlightRow}
                size={'small'}
                height={250} vid={'spectra-list-table'}
                columns={columns} dataSource={stateDisplayTableData}
                rowClassName={record => {
                    return (stateWikiId.id1 + "/" + stateWikiId.id2 || "").toString() === (record.wiki_id || "").toString() ? 'row-active' : '';
                }}
                onRow={record => ({
                    onClick: event => (navigate(`/${record.wiki_id}`, {replace: false, state: "from_click"}))
                })}
                onChange={(pagination, filters, sorter, extra) => {
                    if (sorter) {
                        setTableSorter(sorter)
                    }
                }}
            /></>,
        },
        {
            key: 'plot',
            label: 'Plot',
            children: <>
                <SpectralListPlot plotData={stateDisplayTableData}/>
            </>,
        },
    ]

    return <>
        <SpectralListTableData/>
        <Row justify="end">
        </Row>
        <Row>
            <Col span={24}>
                <Tabs centered defaultActiveKey="list" items={tabItems} tabBarExtraContent={tabExtra}
                      onChange={(key) => {
                          if (key === "list") {
                              setTimeout(() => {
                                  jumpToRow();
                              }, 200);
                          }
                      }}
                />
            </Col>
        </Row>
    </>;
};

export default SpectralListTable;
