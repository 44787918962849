import React, {useEffect} from "react";
import {useAtom} from 'jotai'
import {
    atomGlobalExperimentTrigger,
    atomGlobalSpectrumTrigger,
    atomWikiID,
} from "./Atoms";

import { createModuleLogger } from "../Library/logger";

const logger = createModuleLogger("WikiId");

const WikiId = () => {
    const [atomWikiId] = useAtom(atomWikiID);
    const [stateGlobalAnalysisDataTrigger, setGlobalAnalysisDataTrigger] = useAtom(atomGlobalExperimentTrigger);
    const [stateGlobalSpectrumDataTrigger, setGlobalSpectrumDataTrigger] = useAtom(atomGlobalSpectrumTrigger);

    /////////////////////////////////////////////////////////////////////////////////
    // For atomGlobalAnalysisDataTrigger
    useEffect(() => {
        if (atomWikiId.id1 !== "" && atomWikiId.id1[0] === "a" && atomWikiId.id1 !== stateGlobalAnalysisDataTrigger.wikiId) {
            setGlobalAnalysisDataTrigger({wikiId: atomWikiId.id1, version: stateGlobalAnalysisDataTrigger.version + 1});
        }
    }, [atomWikiId.id1])

    // For atomGlobalSpectrumDataTrigger
    useEffect(() => {
        if (atomWikiId.id1 && atomWikiId.id2 && (atomWikiId.id1 + "/" + atomWikiId.id2) !== stateGlobalSpectrumDataTrigger.wikiId) {
            logger.debug(atomWikiId.id1 + "/" + atomWikiId.id2, stateGlobalSpectrumDataTrigger.wikiId)
            setGlobalSpectrumDataTrigger({
                wikiId: atomWikiId.id1 + "/" + atomWikiId.id2,
                version: stateGlobalSpectrumDataTrigger.version + 1
            });
        } else if (atomWikiId.id1 !== "" && atomWikiId.id1[0] === "s") {
            setGlobalSpectrumDataTrigger({wikiId: atomWikiId.id1, version: stateGlobalSpectrumDataTrigger.version + 1});
        }
    }, [atomWikiId.id1, atomWikiId.id2])

    return <>
    </>
}

export default WikiId;
