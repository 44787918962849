import React, {useEffect} from "react";
import {atom, useAtom} from "jotai";
import {atomGlobalExperiment} from "../../../Data/Atoms";


export const atomSpectralListTableData = atom([]);
export const defaultSpectralListFilter = {
    name_source: "binbase",
    score_cutoff: 0.75,
}
export const atomSpectralListFilter = atom(defaultSpectralListFilter);

export const SpectralListTableData = () => {
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);
    const [, setSpectralListTableData] = useAtom(atomSpectralListTableData);
    const [stateSpectralListFilter,] = useAtom(atomSpectralListFilter);
    useEffect(() => {
        const specData = stateGlobalExperiment.spectra
        if (specData) {
            specData.map((spec, idx) => {
                if (stateSpectralListFilter.name_source === "reference") {
                    spec["name"] = spec["reference_library_search-name"];
                    spec["adduct"] = spec["reference_library_search-adduct"];
                    spec["identity_score"] = spec["reference_library_search-identity_score"];
                    spec["fuzzy_score"] = spec["reference_library_search-fuzzy_score"];
                } else if (stateSpectralListFilter.name_source === "annotation") {
                    spec["name"] = spec["annotation_library_search-name"];
                    spec["adduct"] = spec["annotation_library_search-adduct"];
                    spec["identity_score"] = spec["annotation_library_search-identity_score"];
                    spec["fuzzy_score"] = spec["annotation_library_search-fuzzy_score"];
                } else if (stateSpectralListFilter.name_source === "both") {
                    const isAnnotation = spec["annotation_library_search-identity_score"] >= spec["reference_library_search-identity_score"];
                    spec["name"] = isAnnotation ? spec["annotation_library_search-name"] : spec["reference_library_search-name"];
                    spec["adduct"] = isAnnotation ? spec["annotation_library_search-adduct"] : spec["reference_library_search-adduct"];
                    spec["identity_score"] = Math.max(spec["annotation_library_search-identity_score"], spec["reference_library_search-identity_score"]);
                    spec["fuzzy_score"] = Math.max(spec["annotation_library_search-fuzzy_score"], spec["reference_library_search-fuzzy_score"]);
                } else if (stateSpectralListFilter.name_source === "binbase") {
                    spec["name"] = spec["annotation-name"];
                    spec["adduct"] = spec["annotation-adduct"];
                    spec["identity_score"] = spec["reference_library_search-identity_score"];
                    spec["fuzzy_score"] = spec["reference_library_search-fuzzy_score"];
                }
                if (spec["annotation-name"]) {
                    spec["name"] = spec["annotation-name"];
                    spec["adduct"] = spec["annotation-adduct"];
                }
            })
            setSpectralListTableData(specData)
        }
    }, [stateGlobalExperiment, stateSpectralListFilter])

    return <></>
}