import React, {useEffect} from "react";
import {useAtom} from 'jotai'

import {urlMassWiki} from "../Config/MassWikiUrl";
import {
    atomGlobalReferenceSpectrum,
    atomGlobalSpectrum,
    atomGlobalSpectrumTrigger,
    atomHighlightItem,
    defaultHighlightItem,
} from "./Atoms";
import {useEasyGet} from "../Pages/Global/Message";
import {createModuleLogger} from "../Library/logger";
import {atomAnnotationSearchSelectedTrigger,} from "../Pages/Spectrum/AnnotationLibrary/AnnotationSearchResultData";

const logger = createModuleLogger("GlobalSpectrum");

const GlobalSpectrum = () => {
    const [, setHighlightItem] = useAtom(atomHighlightItem);
    const [stateAtomGlobalSpectrumData, setAtomGlobalSpectrumData] = useAtom(atomGlobalSpectrum);
    const [stateAtomGlobalSpectrumDataTrigger,] = useAtom(atomGlobalSpectrumTrigger);
    const [, setAtomGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);
    const [, setAnnotationSearchSelectedTrigger] = useAtom(atomAnnotationSearchSelectedTrigger);

    /////////////////////////////////////////////////////////////////////////////////
    // For debug
    useEffect(() => {
        logger.debug("stateAtomGlobalSpectrumData", stateAtomGlobalSpectrumData);
    }, [stateAtomGlobalSpectrumData])

    /////////////////////////////////////////////////////////////////////////////////
    const [getMassWikiSpectrumData, messageGetMassWikiSpectrumData] = useEasyGet(
        urlMassWiki.analysis.getData, () => {
            setAtomGlobalReferenceSpectrumData({});
            setHighlightItem(defaultHighlightItem);
            setAtomGlobalSpectrumData({})
        },
        (data) => {
            // logger.warn("getMassWikiSpectrumData", data);
            if (data.spectrum && data.spectrum.spec_uid) {
                setAnnotationSearchSelectedTrigger(data.spectrum.spec_uid);
            }
            setAtomGlobalSpectrumData(data)
        },
        "urlMassWiki.chromMethod.getAnalysisData -> stateAtomGlobalSpectrumData",
        "Loading MassWiki spectrum data...");

    useEffect(() => {
        if (stateAtomGlobalSpectrumDataTrigger.wikiId) {
            getMassWikiSpectrumData.run(stateAtomGlobalSpectrumDataTrigger.wikiId);
        } else {
            getMassWikiSpectrumData.cancel();
        }
    }, [stateAtomGlobalSpectrumDataTrigger])

    return <>
        {messageGetMassWikiSpectrumData}
    </>
}

export default GlobalSpectrum;
