import {atom, useAtom} from "jotai/index";
import {atomGlobalReferenceSpectrum, atomGlobalSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";

import {atomEICData, defaultEICData} from "./EICViewerData";
import {useEffect} from "react";

export const atomExperimentalLibrary = atom([]);
export const atomExperimentalLibraryTrigger = atom({method_id: "", id_list: []});
export const atomExperimentalLibrarySelectedTrigger = atom({
    method_id: "",
    study_id: "",
    file_name: "",
    scan: 0,
});


export default () => {
    const [, setExperimentalLibrary] = useAtom(atomExperimentalLibrary);
    const [stateGlobalReferenceSpectrum, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);
    const [, setEICData] = useAtom(atomEICData);
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Get experimental library data
    const [, messageGetExperimentalLibrary] = useAutoGet(
        atomExperimentalLibraryTrigger,
        (trigger) => (
            trigger.method_id && trigger.id_list && trigger.id_list.length > 0
        ),
        urlMassWiki.experimentalLibrary.getLibraryInfo,
        () => setExperimentalLibrary([]),
        (data) => {
            data.forEach((item) => item["key"] = item["uniq_scan"]);
            setExperimentalLibrary(data)
        },
        "urlMassWiki.experimentalLibrary.getLibraryInfo",
        "Loading Experimental library data..."
    )

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Get selected Experimental library data
    const [, messageGetExperimentalLibrarySelectedData] = useAutoGet(
        atomExperimentalLibrarySelectedTrigger,
        (trigger) => !!(trigger || {}).method_id,
        urlMassWiki.experimentalLibrary.getSelectedLibraryInfo,
        () => {
            setGlobalReferenceSpectrumData({})
            setEICData({...defaultEICData, isShow: true, isLoading: true})
        },
        (data) => {
            // console.log("Selected MS2 library data", data);
            setGlobalReferenceSpectrumData({
                scan: data.scan,
                spectrum: {precursor_mz: data.precursor_mz, peaks: data.peaks},
                metadata: {"Retention Time": data.rt, "Scan": data.scan, "Charge": data.charge},
                eic: {eic: data.eic, rt: data.rt}
            })
        },
        "urlMassWiki.experimentalLibrary.getSelectedLibraryInfo",
        "Loading Experimental library data..."
    );

    useEffect(() => {
        if (stateGlobalReferenceSpectrum.eic) {
            const data = stateGlobalReferenceSpectrum.eic;
            exportDataToEIC(data.eic, data.rt, setEICData);
        } else {
            setEICData({...defaultEICData, isShow: false, isLoading: false});
        }
    }, [stateGlobalReferenceSpectrum]);

    return <>
        {messageGetExperimentalLibrary}
        {messageGetExperimentalLibrarySelectedData}
    </>
}

const findClosestIndex = (arr, target) => {
    // Check if the array is empty
    if (arr.length === 0) {
        return -1; // Return -1 or some error indicator for empty array
    }

    // Initialize the index of the closest number
    let closestIndex = 0;

    // Iterate through the array to find the closest number
    arr.forEach((num, index) => {
        // Update the closest index if the current number is closer to the target
        if (Math.abs(num - target) < Math.abs(arr[closestIndex] - target)) {
            closestIndex = index;
        }
    });

    // Return the index of the closest number
    return closestIndex;
}

const exportDataToEIC = (eic, rtApex, funcSetData) => {
    const rt_array = eic.map((item) => item[0])
    const intensity_array = eic.map((item) => item[1])

    const rtApexIndex = findClosestIndex(rt_array, rtApex);
    const rtApexRegion = [Math.max(0, rtApexIndex - 10), Math.min(rtApex.length, rtApexIndex + 10)];
    const maxIntensity = Math.max(...[...intensity_array.slice(rtApexRegion[0], rtApexRegion[1])]) * 1.3;

    funcSetData({
        ...defaultEICData,
        isShow: true,
        isLoading: false,
        rt: rt_array,
        intensity: intensity_array,
        rtApex: rtApex,
        displayIntensityMax: maxIntensity,
    })
}