import React, {useEffect, useState, useContext} from "react";
import {useAtom} from "jotai";
import {atomGlobalExperiment, atomGlobalSpectrum} from "../../Data/Atoms";
import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useEasyGet} from "../Global/Message";

import {atomBinbaseEICData, defaultBinbaseEICData} from "./BinbaseEIC";

const BinbaseEIC = () => {
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [, setEICData] = useAtom(atomBinbaseEICData);
    const [getBinbaseEic, messageGetBinbaseEic] = useEasyGet(
        urlMassWiki.binbase.getEic,
        () => {
            setEICData({
                ...defaultBinbaseEICData,
                isShow: true,
                isLoading: true,
            })
        },
        (data) => {
            // console.log('Binbase EIC data:', data);
            if(data.data){
                const eicData = data.data.eic || {};
                const rtData = data.data || {};
                setEICData({
                    ...defaultBinbaseEICData,
                    isShow: true,
                    isLoading: false,
                    rt: eicData.retention_times,
                    intensity: eicData.intensities,
                    rtApex: null,
                    rtLeft: rtData.rt_left,
                    rtRight: rtData.rt_right,
                    displayIntensityMax: Math.max(...eicData.intensities),
                })
            }else{
                setEICData({
                    ...defaultBinbaseEICData,
                    isShow: false,
                    isLoading: false,
                })
            }
        },
        "urlMassWiki.binbase.getEic",
        "Loading EIC data..."
    );

    useEffect(() => {
        const metaData = stateGlobalExperiment.metadata;
        const specData = stateGlobalSpectrum.spectrum;
        console.log('Binbase EIC:', metaData, specData)
        if (metaData && metaData.binbase && specData) {
            const binbaseMetaData = metaData.binbase;
            const binbaseData = {
                method: binbaseMetaData.method,
                version: binbaseMetaData.version,
                splash: specData.splash,
            }
            if (binbaseData.method && binbaseData.version && binbaseData.splash) {
                getBinbaseEic.run(binbaseData);
            }
        }
    }, [stateGlobalExperiment, stateGlobalSpectrum]);

    return <>
        {messageGetBinbaseEic}
    </>
};
export default BinbaseEIC;