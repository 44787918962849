import {atom, useAtom} from "jotai/index";
import {atomGlobalExperiment, atomGlobalReferenceSpectrum, atomGlobalSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useEffect, useState} from "react";
import {zip} from "../../../Library/Tools";

export const atomReferenceLibrary = atom([]);
export const atomReferenceRTPredictTrigger = atom({});
// export const atomReferenceLibraryTrigger = atom([]);
export const atomReferenceLibrarySelectedTrigger = atom("");
export const atomSearchType = atom("identity_search");

export default () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateSearchType,] = useAtom(atomSearchType);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomReferenceLibrary
    const globalMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;
    const [stateReferenceLibrary, setReferenceLibrary] = useAtom(atomReferenceLibrary);
    const [, setReferenceRTPredictTrigger] = useAtom(atomReferenceRTPredictTrigger);
    useEffect(() => {
        if (stateGlobalSpectrum.analysis) {
            const globalSpectrumPrecursorMz = (stateGlobalSpectrum.spectrum || {}).precursor_mz;
            const librarySearchScoreList = ((stateGlobalSpectrum.analysis || {}).reference_library || {})[stateSearchType] || [];
            librarySearchScoreList.forEach((d) => {
                d.key = d.library_wiki_id;
                d.entropy_similarity = Number(d.entropy_similarity);
                d.precursor_mz = Number(d.precursor_mz);
                d.rt = Number(d.rt);
                d.delta_mz = d.precursor_mz - globalSpectrumPrecursorMz
                if (d.db === "MassBank.us") {
                    d.link = `https://mona.fiehnlab.ucdavis.edu/spectra/display/${d.id}`;
                } else if (d.db === "GNPS") {
                    d.link = `https://gnps.ucsd.edu/ProteoSAFe/gnpslibraryspectrum.jsp?SpectrumID=${d.id}`;
                }
            })
            setReferenceLibrary(librarySearchScoreList);
            if (globalMethodId && librarySearchScoreList.length > 0) {
                setReferenceRTPredictTrigger([{
                    method_id: globalMethodId,
                    smiles_list: librarySearchScoreList.map((d) => d.smiles)
                }]);
            }
        }
    }, [stateGlobalRunData.metadata, stateGlobalSpectrum.analysis, stateSearchType])

    // For atomReferenceRTPredictTrigger
    const [, messageGetRTPredictData] = useAutoGet(
        atomReferenceRTPredictTrigger,
        (stateTrigger) => stateTrigger.length > 0,
        urlMassWiki.referenceLibrary.rtPredict,
        () => {
        },
        (response) => {
            const globalSpectrumRt = (stateGlobalSpectrum.spectrum || {}).rt;
            const rtPredictData = response.predictions
            zip(rtPredictData, stateReferenceLibrary).forEach((data) => {
                const rtPredict = data[0];
                const libraryData = data[1];
                libraryData.rt_predict = rtPredict ? rtPredict - globalSpectrumRt : NaN;
            })
            setReferenceLibrary([...stateReferenceLibrary]);
        },
        "urlMassWiki.referenceLibrary.rtPredict",
        "Loading RT prediction..."
    );

    // // Get Library data
    // const [, messageGetLibraryMultiData] =
    //     useAutoGet(
    //         atomReferenceLibraryTrigger,
    //         (stateTrigger) => stateTrigger.length > 0,
    //         urlMassWiki.referenceLibrary.getManyByWikiId,
    //         () => setReferenceLibrary([]),
    //         (response) => {
    //             const searchResult = ((stateGlobalSpectrum.analysis || {}).reference_library || {})[stateSearchType] || [];
    //             const annotationData = zip(response, searchResult);
    //             const globalSpectrumPrecursorMz = (stateGlobalSpectrum.spectrum || {}).precursor_mz;
    //             const searchResultData = annotationData.map((data, index) => {
    //                 const info = data[0];
    //                 const score = data[1];
    //                 info.key = info.wiki_id;
    //                 if (info.db === "MassBank.us") {
    //                     info.link = `https://mona.fiehnlab.ucdavis.edu/spectra/display/${info.id}`;
    //                 } else if (info.db === "GNPS") {
    //                     info.link = `https://gnps.ucsd.edu/ProteoSAFe/gnpslibraryspectrum.jsp?SpectrumID=${info.id}`;
    //                 }
    //                 info.score = score[1];
    //                 info.delta_mz = info.precursor_mz - globalSpectrumPrecursorMz
    //                 return info;
    //             })
    //             setReferenceLibrary(searchResultData)
    //         },
    //         "urlMassWiki.library.getManyByWikiId",
    //         "Loading library search result..."
    //     );

    // For atomReferenceLibrarySelectedTrigger
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);
    const [, messageGetLibrarySingleData] =
        useAutoGet(
            atomReferenceLibrarySelectedTrigger,
            (stateTrigger) => !!stateTrigger,
            urlMassWiki.referenceLibrary.getOneByWikiId,
            () => setGlobalReferenceSpectrumData({}),
            (data) => setGlobalReferenceSpectrumData(data),
            "urlMassWiki.library.getOneByWikiId",
            "Loading selected Library data..."
        );


    return <>
        {messageGetRTPredictData}
        {/*{messageGetLibraryMultiData}*/}
        {messageGetLibrarySingleData}
    </>
}