import {atom, useAtom} from "jotai";


export const defaultEICData = {
    isShow: false,
    isLoading: false,
    rt: [],
    intensity: [],
    rtApex: null,
    rtLeft: null,
    rtRight: null,
    displayIntensityMax: null,
}
export const atomEICData = atom(defaultEICData);
