import {Col, Row, Tabs, Tag} from 'antd';
import React, {useEffect} from "react";
import {useAtom} from "jotai";

import VirtualTable from "../../../Library/VirtualTable";
import {atomGlobalReferenceSpectrum, atomGlobalSpectrum, atomHighlightItem} from "../../../Data/Atoms";
import {atomAnnotationModalData, defaultAnnotationData} from "../SubmitAnnotation/UserAnnotationSubmitModal";
import {atomShowModal} from "../SubmitAnnotation/UserAnnotationSubmitModalData";
import LibrarySearchResultData, {
    atomReferenceLibrary,
    atomReferenceLibrarySelectedTrigger,
    atomSearchType
} from "./LibrarySearchResultData";

import {createModuleLogger} from '../../../Library/logger';

const logger = createModuleLogger('LibrarySearchResult');

const LibrarySearchResult = () => {
    const [stateGlobalReferenceSpectrum,] = useAtom(atomGlobalReferenceSpectrum);
    const [stateHighlightItem, setHighlightItem] = useAtom(atomHighlightItem);
    ////////////////////////////////////////////////////////////////////////////////
    // For submission modal
    const [, setAtomShowModal] = useAtom(atomShowModal);
    const [, setAtomSubmissionData] = useAtom(atomAnnotationModalData);
    const setPageSubmissionData = (record) => {
        logger.debug(record)
        setAtomSubmissionData({
            ...defaultAnnotationData,
            type: record.key ? "library_search" : "",
            search_type: stateSearchType,
            mol_name: record.name || "",
            mol_adduct: record.adduct || "",
            mol_smiles: record.key ? ((stateGlobalReferenceSpectrum.metadata || {}).smiles || "") : "",
            db: record.db || "",
            db_id: record.library_wiki_id || "",
            score: record.entropy_similarity,
            delta_mz: record.delta_mz,
        })
        setAtomShowModal(true)
    }

    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const searchResult = (stateGlobalSpectrum.analysis || {}).reference_library || {};
    const [stateSearchType, setSearchType] = useAtom(atomSearchType);
    // Dealing with different search types
    useEffect(() => {
        if ((searchResult.identity_search || []).length > 0) {
            setSearchType("identity_search")
        } else if ((searchResult.open_search || []).length > 0) {
            setSearchType("open_search")
        } else if ((searchResult.neutral_loss_search || []).length > 0) {
            setSearchType("neutral_loss_search")
        } else if ((searchResult.hybrid_search || []).length > 0) {
            setSearchType("hybrid_search")
        } else {
            setSearchType("identity_search")
        }
    }, [searchResult]);

    // //////////////////////////////////////////////////////////////////////////////
    // For display library data
    const [stateReferenceLibrary,] = useAtom(atomReferenceLibrary);
    const [stateGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);
    const [, setReferenceLibrarySelectedTrigger] = useAtom(atomReferenceLibrarySelectedTrigger);
    const column = [
        {
            title: 'DB', dataIndex: 'db', key: 'db', ellipsis: false, width: 60,
        }, {
            title: 'ID', dataIndex: 'id', key: 'id', ellipsis: false, width: 80
        }, {
            title: 'Name', dataIndex: 'name', key: 'name', ellipsis: false, width: 120
        }, {
            title: 'Adduct', dataIndex: 'adduct', key: 'adduct', ellipsis: false, width: 80
        }, {
            title: 'Entropy similarity',
            dataIndex: 'entropy_similarity',
            key: 'entropy_similarity',
            sorter: (a, b) => a.entropy_similarity - b.entropy_similarity,
            defaultSortOrder: 'descend',
            ellipsis: false,
            width: 80,
            render: (_, record) => record.entropy_similarity.toFixed(3),
        }, {
            title: 'Δ mass',
            dataIndex: 'delta_mz',
            key: 'delta_mz',
            sorter: (a, b) => a.delta_mz - b.delta_mz,
            ellipsis: false,
            width: 50,
            render: (_, record) => record.delta_mz.toFixed(3),
        }, {
            title: 'Δ predicted RT', dataIndex: 'rt_predict', key: 'rt_predict', ellipsis: false, width: 80,
            sorter: (a, b) => a.rt_predict - b.rt_predict,
            render: (_, record) => record.rt_predict ? record.rt_predict.toFixed(1) : "-"
        }, {
            title: 'Link', dataIndex: 'link', key: 'link', ellipsis: false, width: 50, render: (_, record) => (<>
                {record.link ? <a href={record.link} target="_blank">View</a> : <span>-</span>} </>),
        }, {
            title: "", width: 70,
            render: (_, record) => (
                stateHighlightItem.type === "reference" && (stateHighlightItem.id === `${record.key}`) ?
                    <Tag color={"red"} onClick={() => {
                        logger.warn("Assign molecule", record)
                        setPageSubmissionData(record)
                    }}>
                        Assign molecule
                    </Tag> : <>
                    </>
            )
        }
    ];

    const tabExtra = <>
        <Tag color={"red"} onClick={() => setPageSubmissionData({})}>
            Add annotation
        </Tag>
    </>

    return <>
        <LibrarySearchResultData/>
        <Row>
            <Col span={24}>
                <Tabs defaultActiveKey="identity_search" centered onChange={(k) => setSearchType(k)}
                      activeKey={stateSearchType}
                      tabBarExtraContent={tabExtra}
                      items={[{
                          key: "identity_search", label: "Identity search",
                          disabled: (searchResult.identity_search || []).length === 0
                      }, {
                          key: "open_search", label: "Open search",
                          disabled: (searchResult.open_search || []).length === 0
                      }, {
                          key: "neutral_loss_search", label: "Neutral loss search",
                          disabled: (searchResult.neutral_loss_search || []).length === 0
                      }, {
                          key: "hybrid_search", label: "Hybrid search",
                          disabled: (searchResult.hybrid_search || []).length === 0
                      }]}/>
                <VirtualTable
                    vid={"reference-library-result-table"}
                    // loading={getLibraryData.loading}
                    onRow={record => ({
                        onClick: event => {
                            setHighlightItem({type: "reference", id: `${record.key}`})
                            setReferenceLibrarySelectedTrigger(record.key)
                        }
                    })}
                    rowClassName={record => {
                        return stateHighlightItem.type === "reference" && (stateHighlightItem.id === `${record.key}`) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateReferenceLibrary}/>
            </Col>
        </Row>
    </>;
};

export default LibrarySearchResult;
