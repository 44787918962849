import {Col, Row, Tabs, Tag} from 'antd';
import React, {useEffect} from "react";
import {useAtom} from "jotai";
import VirtualTable from "../../../Library/VirtualTable";
import {atomGlobalReferenceSpectrum, atomGlobalSpectrum, atomHighlightItem} from "../../../Data/Atoms";
import {atomAnnotationModalData, defaultAnnotationData} from "../SubmitAnnotation/UserAnnotationSubmitModal";
import {atomShowModal} from "../SubmitAnnotation/UserAnnotationSubmitModalData";
import AnnotationSearchResultData, {
    atomAnnotationSearch,
    atomAnnotationSearchSelectedTrigger,
    atomAnnotationSearchType,
} from "./AnnotationSearchResultData";
import {createModuleLogger} from '../../../Library/logger';

const logger = createModuleLogger('AnnotationSearchResult');

const AnnotationSearchResult = () => {
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateGlobalReferenceSpectrum,] = useAtom(atomGlobalReferenceSpectrum);
    const [stateHighlightItem, setHighlightItem] = useAtom(atomHighlightItem);
    useEffect(() => {
        logger.debug("stateHighlightItem", stateHighlightItem)
    }, [stateHighlightItem])
    ////////////////////////////////////////////////////////////////////////////////
    // Add assign annotation button
    const [, setAtomShowModal] = useAtom(atomShowModal);
    const [, setAtomSubmissionData] = useAtom(atomAnnotationModalData);
    const setPageSubmissionData = (record) => {
        logger.debug("setPageSubmissionData", record)
        setAtomSubmissionData({
            ...defaultAnnotationData,
            type: record.key ? "annotation_search" : "",
            search_type: stateAnnotationSearchType,
            mol_name: record.mol_name || "",
            mol_adduct: record.mol_adduct || "",
            mol_smiles: record.key ? (stateGlobalReferenceSpectrum.metadata || {}).smiles || "" : "",
            db: record.db || "",
            db_id: record.library_id || "",
            score: record.score,
            delta_mz: record.deltaMz,
            annotation_user_name: record.user_name || "",
        })
        setAtomShowModal(true)
    }

    ////////////////////////////////////////////////////////////////////////////////
    const searchResult = (stateGlobalSpectrum.analysis || {}).annotation_library || {};
    const [stateAnnotationSearchType, setAnnotationSearchType] = useAtom(atomAnnotationSearchType);
    // Dealing with different search types
    useEffect(() => {
        if ((searchResult.identity_search || []).length > 0) {
            setAnnotationSearchType("identity_search")
        } else if ((searchResult.open_search || []).length > 0) {
            setAnnotationSearchType("open_search")
        } else if ((searchResult.neutral_loss_search || []).length > 0) {
            setAnnotationSearchType("neutral_loss_search")
        } else if ((searchResult.hybrid_search || []).length > 0) {
            setAnnotationSearchType("hybrid_search")
        } else {
            setAnnotationSearchType("identity_search")
        }
    }, [searchResult]);


    ////////////////////////////////////////////////////////////////////////////////
    const [stateGlobalReferenceSpectrumData,] = useAtom(atomGlobalReferenceSpectrum);
    const [stateAnnotationSearch] = useAtom(atomAnnotationSearch);
    const [, setAnnotationSearchSelectedDataTrigger] = useAtom(atomAnnotationSearchSelectedTrigger);
    // For table column
    const column = [
        {
            title: 'Name', dataIndex: 'mol_name', key: 'mol_name', ellipsis: false, width: 180
        }, {
            title: 'Method ID', dataIndex: 'method_id', key: 'method_id', ellipsis: false, width: 80
        }, {
            title: 'Adduct', dataIndex: 'mol_adduct', key: 'mol_adduct', ellipsis: false, width: 80
        }, {
            title: 'Entropy similarity',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            defaultSortOrder: 'descend',
            ellipsis: false,
            width: 80,
            render: (_, record) => record.score.toFixed(3),
        }, {
            title: 'Δ RT',
            dataIndex: 'deltaRt',
            key: 'deltaRt',
            sorter: (a, b) => a.deltaRt - b.deltaRt,
            ellipsis: false,
            width: 50,
            render: (_, record) => (record.deltaRt === null) ? "-" : record.deltaRt.toFixed(1),
        }, {
            title: 'Δ mass',
            dataIndex: 'deltaMz',
            key: 'deltaMz',
            sorter: (a, b) => a.deltaMz - b.deltaMz,
            ellipsis: false,
            width: 50,
            render: (_, record) => record.deltaMz.toFixed(3),
        }, {
            title: 'By', dataIndex: 'user_name', key: 'user_name', ellipsis: false, width: 80
        }, {
            title: "", width: 70,
            render: (_, record) => (
                stateHighlightItem.type === "annotation" && (stateHighlightItem.id === `${record.library_id}`) ?
                    <Tag color={"red"} onClick={() => {
                        setPageSubmissionData(record)
                    }}>
                        Assign molecule
                    </Tag> : <>
                    </>
            )
        }
    ]

    const tabExtra = <>
        <Tag color={"red"} onClick={() => setPageSubmissionData({})}>
            Add annotation
        </Tag>
    </>

    return <>
        <AnnotationSearchResultData/>
        <Row>
            <Col span={24}>
                <Tabs defaultActiveKey="identity_search" centered onChange={(k) => setAnnotationSearchType(k)}
                      activeKey={stateAnnotationSearchType}
                      tabBarExtraContent={tabExtra}
                      items={[{
                          key: "identity_search", label: "Identity search",
                          disabled: (searchResult.identity_search || []).length === 0
                      }, {
                          key: "open_search", label: "Open search",
                          disabled: (searchResult.open_search || []).length === 0
                      }, {
                          key: "neutral_loss_search", label: "Neutral loss search",
                          disabled: (searchResult.neutral_loss_search || []).length === 0
                      }, {
                          key: "hybrid_search", label: "Hybrid search",
                          disabled: (searchResult.hybrid_search || []).length === 0
                      }]}/>
                <VirtualTable
                    vid={"annotation-library-result-table"}
                    onRow={record => ({
                        onClick: event => {
                            setHighlightItem({type: "annotation", id: record.library_id})
                            setAnnotationSearchSelectedDataTrigger(record.spec_uid)
                        }
                    })}
                    rowClassName={record => {
                        return stateHighlightItem.type === "annotation" && (`${stateHighlightItem.id}` === `${record.library_id}`) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateAnnotationSearch}/>
            </Col>
        </Row>
    </>;
};

export default AnnotationSearchResult;
