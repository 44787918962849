import {message} from 'antd';
import React, {useEffect} from 'react';
import {atom, useAtom} from "jotai";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {
    atomGlobalExperiment,
    atomGlobalExperimentTrigger,
    atomGlobalSpectrum,
    atomGlobalSpectrumTrigger,
    atomUserInfo,
    atomWebsiteMode,
    atomWikiID,
    PERMISSION_TYPE,
    SPECTRA_TYPE
} from "../../../Data/Atoms";
import {useAutoGetWithPreprocess} from "../../Global/Message";

import {createModuleLogger} from '../../../Library/logger';

const logger = createModuleLogger('UserAnnotationSubmitModalData');

export const atomShowModal = atom(false);
export const atomSubmissionTrigger = atom({shouldSubmit: false});
export const atomAnnotationVersion = atom(0);

const UserAnnotationSubmitModalData = () => {
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateWebsiteMode,] = useAtom(atomWebsiteMode);
    const [stateUserInfo,] = useAtom(atomUserInfo);

    const [stateAnnotationVersion, setAnnotationVersion] = useAtom(atomAnnotationVersion);
    const [, setAtomShowModal] = useAtom(atomShowModal);

    const [stateSubmissionTrigger, setSubmissionTrigger] = useAtom(atomSubmissionTrigger);
    useEffect(() => {
        logger.debug("stateSubmissionTrigger", stateSubmissionTrigger)
    }, [stateSubmissionTrigger]);

    const [, messageSubmitAnnotation] = useAutoGetWithPreprocess(
        atomSubmissionTrigger,
        (trigger) => trigger.shouldSubmit && stateUserInfo.name !== "",
        (stateSubmissionTrigger) => {
            const chromWikiId = ((stateGlobalExperiment.metadata || {}).method || {}).library_id || ""
            const specData = stateGlobalSpectrum.spectrum
            const finalData = {
                spec_wiki_id: stateGlobalSpectrum.wiki_id,
                method_id: chromWikiId,
                rt_normalized: specData.rt_normalized || specData.rt,
                spectrum: specData,
                mol_data: {
                    name: stateSubmissionTrigger.mol_name,
                    smiles: stateSubmissionTrigger.mol_smiles,
                    adduct: stateSubmissionTrigger.mol_adduct,
                },
                annotation_data: {
                    comments: stateSubmissionTrigger.comments,
                    labels: [],
                },
                metadata: {
                    source: SPECTRA_TYPE.USER,
                },
            }
            if (stateSubmissionTrigger.is_hidden) {
                finalData.annotation_data.labels.push("hidden")
            }
            if (stateWebsiteMode.mode === SPECTRA_TYPE.LCBINBASE && stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
                const binbaseRunData = (stateGlobalExperiment.metadata || {}).binbase || {}
                const binbaseSpectrumData = (stateGlobalSpectrum.abstract_data || {})["binbase-splash"] || ""
                finalData.metadata.binbase = {
                    'method': binbaseRunData.method || '',
                    'version': binbaseRunData.version || '',
                    'splash': binbaseSpectrumData || '',
                }
                finalData.metadata.source = SPECTRA_TYPE.LCBINBASE
            }
            return finalData;
        },
        urlMassWiki.annotation.submit,
        () => {
        },
        () => {
            setAtomShowModal(false);
            setAnnotationVersion(stateAnnotationVersion + 1);
            message.success("The annotation has been submitted successfully.");
        },
        "urlMassWiki.annotation.submit",
        "Submitting user annotation search results...");

    // Reload the spectrum data after submitting the annotation
    const [stateWikiId,] = useAtom(atomWikiID);
    const [stateGlobalExperimentTrigger, setGlobalExperimentTrigger] = useAtom(atomGlobalExperimentTrigger);
    const [stateGlobalSpectrumTrigger, setGlobalSpectrumTrigger] = useAtom(atomGlobalSpectrumTrigger);
    const [, messageReloadSpectrum] = useAutoGetWithPreprocess(
        atomAnnotationVersion,
        (trigger) => trigger > 0,
        (stateAnnotationVersion) => {
            if (stateWikiId.id1 && stateWikiId.id2) {
                return stateWikiId.id1 + "/" + stateWikiId.id2
            } else {
                return stateWikiId.id1
            }
        },
        urlMassWiki.analysis.reanalyzeOneSpectrum,
        () => {
            setSubmissionTrigger({...stateSubmissionTrigger, shouldSubmit: false});
        },
        () => {
            setAnnotationVersion(0);
            if (stateWikiId.id1 && stateWikiId.id2) {
                setGlobalExperimentTrigger({
                    ...stateGlobalExperimentTrigger,
                    version: stateGlobalExperimentTrigger.version + 1
                });
            } else {
                setGlobalSpectrumTrigger({
                    ...stateGlobalSpectrumTrigger,
                    version: stateGlobalSpectrumTrigger.version + 1
                });
            }
        },
        "urlMassWiki.analysis.reanalyzeOneSpectrum",
        "Reloading spectrum data..."
    );

    return <>
        {messageSubmitAnnotation}
        {messageReloadSpectrum}
    </>
}

export default UserAnnotationSubmitModalData
