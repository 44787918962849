import {atom, useAtom} from "jotai/index";
import {atomGlobalExperiment, atomGlobalReferenceSpectrum, atomGlobalSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useEffect} from "react";
import {createModuleLogger} from "../../../Library/logger";

// Atom for Annotation Data
export const atomAnnotationSearch = atom([]);
export const atomAnnotationSearchSelected = atom([]);
export const atomAnnotationSearchSelectedTrigger = atom("");
export const atomAnnotationSearchType = atom("identity_search");

// const atomAnnotationSearchTrigger = atom([]);
const logger = createModuleLogger("AnnotationSearchResultData");

const AnnotationSearchResultData = () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateAnnotationSearchType,] = useAtom(atomAnnotationSearchType);

    const [, setAnnotationSearchTableData] = useAtom(atomAnnotationSearch);
    const [, setAnnotationSearchSelected] = useAtom(atomAnnotationSearchSelected);
    const [, setGlobalReferenceSpectrum] = useAtom(atomGlobalReferenceSpectrum);

    // For get user annotation search table data
    // const [, setAnnotationSearchTableDataTrigger] = useAtom(atomAnnotationSearchTrigger);
    useEffect(() => {
        if (stateGlobalSpectrum.analysis) {
            const globalSpectrumPrecursorMz = (stateGlobalSpectrum.spectrum || {}).precursor_mz;
            const globalMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;
            const annotationSearchScoreList = ((stateGlobalSpectrum.analysis || {}).annotation_library || {})[stateAnnotationSearchType] || [];
            const globalSpectrumRt = (stateGlobalSpectrum.spectrum || {}).rt_normalized || (stateGlobalSpectrum.spectrum || {}).rt;
            let tableData = annotationSearchScoreList.map((d, i) => {
                return {
                    key: i,
                    mol_name: d.name,
                    method_id: d.db,
                    mol_adduct: d.adduct,
                    score: d.entropy_similarity,
                    deltaRt: d.db === globalMethodId ? Number(d.rt) - globalSpectrumRt : null,
                    deltaMz: Number(d.precursor_mz) - globalSpectrumPrecursorMz,
                    user_name: d.user_name,
                    library_id: d.id,
                    spec_uid: d.spec_uid,
                }
            });
            setAnnotationSearchTableData(tableData)
        }
    }, [stateGlobalRunData.metadata, stateGlobalSpectrum.analysis, stateAnnotationSearchType])

    const [, messageGetAnnotationSingleData] =
        useAutoGet(
            atomAnnotationSearchSelectedTrigger,
            (trigger) => !!trigger,
            urlMassWiki.annotation.getAnnotationDetails,
            () => {
                setAnnotationSearchSelected([])
                setGlobalReferenceSpectrum({})
            },
            (data) => {
                // logger.warn(data)
                if (data.length > 0) {
                    setAnnotationSearchSelected(data)
                    // logger.warn("data", data)
                    data = data[0]
                    const userInfo = data.data.user
                    const molInfo = data.data.mol

                    const refData = {
                        library_id: data.id,
                        spec_uid: data.spectrum.spec_uid,
                        method_id: data.spectrum.method_id,
                        spectrum: data.spectrum,
                        metadata: {
                            "Annotator": userInfo.name,
                            "Original retention Time": data.spectrum.rt_original,
                            "Normalized retention Time": data.spectrum.rt,
                            "Molecule Name": molInfo.name,
                            "smiles": molInfo.smiles,
                            "Adduct": molInfo.adduct,
                            "Comment": data.data.annotation.comments,
                            "Chromatography Method ID": data.spectrum.method_id,
                        }
                    }
                    // logger.warn("refData", refData)
                    setGlobalReferenceSpectrum(refData)
                }
            },
            "urlMassWiki.annotation.getAnnotationDetails",
            "Loading selected annotation data..."
        )

    return <>
        {messageGetAnnotationSingleData}
    </>
}

export default AnnotationSearchResultData;
