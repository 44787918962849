import {atom, useAtom} from "jotai/index";
import {atomGlobalReferenceSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";

export const atomInternalLibrarySelectedTrigger = atom("");

export default () => {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomReferenceLibrary
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);

    // For atomReferenceLibrarySelectedTrigger
    const [,messageGetLibrarySingleData] =
        useAutoGet(
            atomInternalLibrarySelectedTrigger,
            (stateTrigger) => !!stateTrigger,
            urlMassWiki.internalLibrary.getOneByWikiId,
            () => setGlobalReferenceSpectrumData({}),
            (data) => {
                // console.log(data)
                setGlobalReferenceSpectrumData(data)
            },
            "urlMassWiki.library.internal.getOneByWikiId",
            "Loading selected Library data..."
        );


    return <>
        {messageGetLibrarySingleData}
    </>
}