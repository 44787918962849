import {Col, Row, Table, Button, notification } from 'antd';
import React, {useState, useEffect} from "react";
import {useAtom} from 'jotai'
import {urlMassWiki} from "../../Config/MassWikiUrl";

import {useEasyGet} from "../Global/Message";
import {atomWebsiteMode, PERMISSION_TYPE} from "../../Data/Atoms";

import {createModuleLogger} from "../../Library/logger";
const logger = createModuleLogger("BinBaseLibrary");

const BinBaseLibrary = () => {
    const [stateWebsiteMode,] = useAtom(atomWebsiteMode);
    useEffect(() => {
        if (stateWebsiteMode.permission === PERMISSION_TYPE.PUBLIC) {
            getBinbaseAllLibrary.run(PERMISSION_TYPE.PUBLIC);
        } else if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
            getBinbaseAllLibrary.run(PERMISSION_TYPE.INTERNAL);
        }
    }, [stateWebsiteMode.permission]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Update binbase library table
    const [stateBinbaseLibrary, setBinbaseLibrary] = useState([]);
    const funcProcessingLibraryList = (data) => {
        data.forEach((d) => {
            d.key = d.wiki_id ? d.wiki_id : d.method;
            d.time_updated = d.time_updated ? (new Date(d.time_updated * 1000)).toLocaleString() : "";
        })
        logger.debug(data)
        return data;
    }
    const [getBinbaseAllLibrary, messageGetBinbaseAllLibrary] = useEasyGet(
        urlMassWiki.binbase.getAllLibrary,
        () => setBinbaseLibrary([]),
        (data) => setBinbaseLibrary(funcProcessingLibraryList(data)),
        "getBinbaseAllLibrary", "Loading binbase library list...");

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For update button
    const [stateBinbaseUpdateMethod, setBinbaseUpdateMethod] = useState({});
    useEffect(() => {
        logger.debug("stateBinbaseUpdateMethod", stateBinbaseUpdateMethod)
    }, [stateBinbaseUpdateMethod]);

    const [getBinbaseUpdateMethod, messageGetBinbaseUpdateMethod] = useEasyGet(
        urlMassWiki.binbase.update_binbase,
        () => setBinbaseUpdateMethod({}),
        (data) => {
            notification.success({message: 'Success send request, please wait up to 1 hour for update to be finished',})
            // Refresh the current page
            setInterval(() => window.location.reload(), 3000)
        },
        "urlMassWiki.binbase.update_binbase", "Updating method...");

    const columns = [
        {
            title: 'Method name',
            dataIndex: 'method',
            key: 'method',
            render: (text, record) => (<>{
                record.wiki_id ? <a href={`/${record.wiki_id}`}>{record.method}</a> : <>{record.method}</>
            }</>)
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }, {
            title: 'Updated time',
            dataIndex: 'time_updated',
            key: 'time_updated',
            render: (text, record) => <>{text}</>
        }];
    if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
        columns.push({
            title: '',
            key: 'action',
            render: (text, record) => <Button
                disabled={record.status === "Processing"}
                onClick={(event) => {
                    getBinbaseUpdateMethod.run(record.wiki_id, record.method, record.version)
                }}>Update</Button>
        })
    }

    return <>
        {messageGetBinbaseAllLibrary}
        {messageGetBinbaseUpdateMethod}
        <Row justify={"center"}>
            <Col span={12}>
                <Table pagination={false} loading={getBinbaseAllLibrary.loading}
                       columns={columns} dataSource={stateBinbaseLibrary}/>
            </Col>
        </Row>
    </>
}

export default BinBaseLibrary;
