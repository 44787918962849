import {Col, Row, Tag} from 'antd';
import React, {useEffect, useState} from "react";
import {useAtom} from "jotai";

import VirtualTable from "../../../Library/VirtualTable";
import {
    atomGlobalExperiment,
    atomGlobalReferenceSpectrum,
    atomGlobalSpectrum,
    atomHighlightItem
} from "../../../Data/Atoms";
import LibrarySearchResultData, {atomInternalLibrarySelectedTrigger} from "./LibrarySearchResultData";
import {useNavigate} from "react-router-dom";

export const InternalLibrarySearchResult = () => {
    const navigate = useNavigate();
    const [stateHighlightItem, setHighlightItem] = useAtom(atomHighlightItem);
    const [stateGlobalAnalysis,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);

    ////////////////////////////////////////////////////////////////////////////////
    // For search type
    const [stateSearchType, setSearchType] = useState("ms2_similar");
    const [stateTableData, setTableData] = useState([]);

    // //////////////////////////////////////////////////////////////////////////////
    // For get library search table data
    useEffect(() => {
            const allSpectra = stateGlobalAnalysis.spectra || [];
            const rawSearchResult = ((stateGlobalSpectrum.analysis || {}).internal_library || {})[stateSearchType] || {};
            if (allSpectra.length > 0 && rawSearchResult.length > 0) {
                const globalSpectrum = stateGlobalSpectrum.spectrum || {};
                let searchResults = rawSearchResult.map((d, idx, arr) => {
                    let rowSpec = allSpectra.filter((s) => s.scan === d[0]);
                    if (rowSpec.length === 0) {
                        return {key: idx, score: d[1], delta_rt: 0, delta_mz: 0}
                    } else {
                        rowSpec = rowSpec[0]
                        return {
                            ...rowSpec,
                            key: idx,
                            score: d[1],
                            delta_rt: rowSpec.rt_normalized - globalSpectrum.rt_normalized,
                            delta_mz: rowSpec.precursor_mz - globalSpectrum.precursor_mz
                        }
                    }
                });
                searchResults = searchResults.filter((d) => d.scan !== globalSpectrum.scan);
                // console.log("searchResults", searchResults)
                setTableData(searchResults)
            }
        }
        ,
        [stateGlobalAnalysis, stateGlobalSpectrum, stateSearchType]
    )


    ////////////////////////////////////////////////////////////////////////////////
    // For table column
    const [stateGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);


    const column = [
        {
            title: 'Scan', dataIndex: 'scan', key: 'scan', ellipsis: false, width: 60
        }, {
            title: 'Name', dataIndex: 'name', key: 'name', ellipsis: true
        }, {
            title: 'Adduct', dataIndex: 'adduct', key: 'adduct', ellipsis: false, width: 120
        }, {
            title: 'Δ RT',
            dataIndex: 'delta_rt',
            key: 'delta_rt',
            sorter: (a, b) => a.delta_rt - b.delta_rt,
            ellipsis: false,
            width: 80,
            render: (_, record) => record.delta_rt.toFixed(3),
        }, {
            title: 'Δ mass',
            dataIndex: 'delta_mz',
            key: 'delta_mz',
            sorter: (a, b) => a.delta_mz - b.delta_mz,
            ellipsis: false,
            width: 80,
            render: (_, record) => record.delta_mz.toFixed(3),
        }, {
            title: 'Hybrid search similarity',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            defaultSortOrder: 'descend',
            ellipsis: false,
            width: 160,
            render: (_, record) => record.score.toFixed(3),
        }, {
            title: "", width: 90,
            render: (_, record) => (
                stateHighlightItem.type === "internal_library" && (stateHighlightItem.id === record.key) ?
                    <Tag color={"blue"} onClick={() =>
                        navigate(`/${record.wiki_id}`, {replace: false})}>
                        View details
                    </Tag> : <>
                    </>
            )
        }
    ];
    const [, setReferenceLibrarySelectedTrigger] = useAtom(atomInternalLibrarySelectedTrigger);

    return <>
        <LibrarySearchResultData/>
        <Row>
            <Col span={24}>
                <VirtualTable
                    vid={"internal-library-result-table"}
                    // loading={getLibraryData.loading}
                    onRow={record => ({
                        onClick: event => {
                            setHighlightItem({type: "internal_library", id: record.key});
                            setReferenceLibrarySelectedTrigger(record.wiki_id)
                        }
                    })}
                    rowClassName={record => {
                        return stateHighlightItem.type === "internal_library" && (stateHighlightItem.id === record.key) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateTableData}/>
            </Col>
        </Row>
    </>;
};
