import {Button, Modal, Col, Row, Tabs, Space, Form, Tag, Input, Avatar} from 'antd';
import React, {useState, useContext, useEffect} from 'react';
import {atom, useAtom} from "jotai";
import {useCookies} from "react-cookie";
import {UserOutlined, LoginOutlined} from '@ant-design/icons';
import {UserLogin} from "./UserLogin";
import {atomUserInfo, atomUserInfoTrigger, atomWebsiteMode, PERMISSION_TYPE, SPECTRA_TYPE} from "../../Data/Atoms";


const fontStyles = {
    fontSize: "1.2em",
    padding: "0em",
    color: "#000000",
    // color: "#69b1ff",
};

export default () => {
    const [stateUserInfo,] = useAtom(atomUserInfo);
    const [stateUserInfoTrigger, setUserInfoTrigger] = useAtom(atomUserInfoTrigger);
    const clickUser = () => {
        setUserInfoTrigger(true);
    }

    const [stateWebsiteMode, setStateWebsiteMode] = useAtom(atomWebsiteMode);
    useEffect(() => {
        if (window.location.href.includes("localhost") || window.location.href.includes("lab.mass.wiki") || window.location.href.includes("new.mass.wiki")) {
            setStateWebsiteMode({...stateWebsiteMode, permission: PERMISSION_TYPE.INTERNAL});
        } else {
            setStateWebsiteMode({...stateWebsiteMode, permission: PERMISSION_TYPE.PUBLIC});
        }
    }, []);
    useEffect(() => {
        if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL && (!stateUserInfo.email.endsWith("@ucdavis.edu"))) {
            setUserInfoTrigger(true);
        }
    }, [stateUserInfoTrigger, stateWebsiteMode.permission]);

    return <>
        <UserLogin/>
        <Space align={"baseline"}>
            {
                stateUserInfo.email ? <>
                    <Avatar style={{backgroundColor: '#69b1ff'}} icon={<UserOutlined/>} onClick={clickUser}/>
                    <a onClick={clickUser} style={fontStyles}>{stateUserInfo.name}</a>
                </> : <>
                    <LoginOutlined onClick={clickUser} style={{...fontStyles, color: '#69b1ff'}}/>
                    <a onClick={clickUser} style={fontStyles}>Login / Register</a>
                </>
            }
        </Space>
    </>
}