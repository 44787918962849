import { Col, Row, Space, Timeline, Card, Avatar, Empty, List, Form, Button, Input, Alert, Popconfirm, Typography, Spin, Divider, Collapse } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { usePlotMolecule } from '../../../Library/PlotMolecule';
import { atomAnnotationSearchSelected } from '../AnnotationLibrary/AnnotationSearchResultData';
import { UserOutlined } from '@ant-design/icons';
import { FaThumbsUp } from 'react-icons/fa';
import { createModuleLogger } from '../../../Library/logger';
import { urlMassWiki } from '../../../Config/MassWikiUrl';
import Cookies from 'js-cookie';

const logger = createModuleLogger('UserAnnotation');

const UserAnnotationCard = (props) => {
  const element = props.element;
  const annotationMol = element.mol || {};
  const annotationAnnotation = element.annotation || {};
  const annotationUser = element.user || {};
  const time = props.time;

  const [contextPlotMolecule, setPlotMoleculeSmiles] = usePlotMolecule({ width: 150, height: 120 });
  const refPlot = useRef(null);

  useEffect(() => {
    if (annotationMol.smiles && refPlot.current) {
      setPlotMoleculeSmiles(annotationMol.smiles);
    }
  }, [annotationMol.smiles]);

  return (
    <div style={{ padding: '16px' }}>
      <Card
        style={{
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          padding: '16px',
          marginBottom: '16px',
        }}
      >
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col span={4}>
            <Row justify="center" align="middle">
              <Avatar
                size={64}
                style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', marginBottom: '8px' }}
                icon={<UserOutlined />}
              >
                {annotationUser.name[0]}
              </Avatar>
              <Space direction="vertical" style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginBottom: 0, fontSize: '18px', fontWeight: 'bold' }}>{annotationUser.name}</h3>
                <p style={{ color: '#777' }}>{new Date(time + 'Z').toLocaleString().split(',')[0]}</p>
              </Space>
            </Row>
          </Col>
          <Col span={16}>
            <Space direction="vertical" size={4}>
              <Space>
                <Typography.Title level={5} style={{ margin: 0 }}>Molecular name:</Typography.Title>
                <Typography.Text>{annotationMol.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Title level={5} style={{ margin: 0 }}>SMILES:</Typography.Title>
                <Typography.Text>{annotationMol.smiles}</Typography.Text>
              </Space>
              <Space>
                <Typography.Title level={5} style={{ margin: 0 }}>Adduct:</Typography.Title>
                <Typography.Text>{annotationMol.adduct}</Typography.Text>
              </Space>
              <Space>
                <Typography.Title level={5} style={{ margin: 0 }}>Comment:</Typography.Title>
                <Typography.Text>{annotationAnnotation.comments || 'No comments available.'}</Typography.Text>
              </Space>
            </Space>
          </Col>
          <Col span={4}>
            <Row justify="center" align="middle" ref={refPlot}>
              {contextPlotMolecule}
            </Row>
            <Row>
                  {element.library_id && <LikeComponent annotation_id={element.library_id} />}    
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            {element.library_id && <ReplyComponent annotation_id={element.library_id} />}   
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const LikeComponent = ({annotation_id}) => {
  const [liked, setLiked] = useState(""); // Track like click by current user
  const [likeCount, setLikeCount] = useState(0); // Track total likes

  const fetchLike = async () => {
    try {
      const response = await urlMassWiki.annotation.getLikes(annotation_id);
      setLiked(response.liked);
      setLikeCount(response.num_of_likes);
    } catch (error) {
      logger.error('Error fetching likes:', error);
    }
  }

  const toggleLike = async () => {
    try {
      const response = await urlMassWiki.annotation.toggleLike(annotation_id);
      setLiked(response.liked);
      setLikeCount(response.num_of_likes);
    } catch (error) {
      logger.error('Error toggling like:', error);
    }
  };

  useEffect(
    () => {
      fetchLike();
    }, [annotation_id]
  );

  return (
    <div>
      <button
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          transition: 'transform 0.2s ease',
        }}
        onClick={toggleLike}
      >
        <FaThumbsUp
          size="2em"
          color={liked === true ? '#1890ff' : '#bbb'} // Blue for upvoted
          style={{ transform: liked === true ? 'scale(1.2)' : 'scale(1)' }}
        />
      </button>
      <Typography.Title level={5}>Likes: {likeCount}</Typography.Title>
    </div>
  );
};


const ReplyComponent = ({annotation_id}) => {
  const [repliesList, setRepliesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationType, setNotificationType] = useState('');
  const [currentUser, ] = useState(Cookies.get().userName)

  const fetchReplies = async () => {
    setLoading(true);
    setNotificationMessage(null);
    setNotificationType('');

    try {
      const response = await urlMassWiki.annotation.getReplies(annotation_id);

      if (!response || !response.replies || !Array.isArray(response.replies)) {
        throw new Error('Invalid response from server');
      }
      
      setRepliesList(response.replies);
    } catch (error) {
      setNotificationType('error');
      setNotificationMessage("Cannot retrieve replies: " + error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchReplies();
  }, [annotation_id])

  const onFinish = async (value) => {
    try {
      const response = await urlMassWiki.annotation.addReply(annotation_id, value);
      if (response.status === "failure") {
        setNotificationType('error');
        setNotificationMessage(response.message);
      } else {
        setNotificationType('success');
        setNotificationMessage(response.message);
      }
      setTimeout(() => {
        fetchReplies();
      }, 2500);
    } catch (error) {
      setNotificationType('error');
      setNotificationMessage('Error submitting reply ' + error.message);
    }
  }

  const onReset = () => {
    form.resetFields();
  }

  const onDelete = async (reply_timestamp) => {
    try {
      const response = await urlMassWiki.annotation.deleteReply(annotation_id, currentUser, reply_timestamp);
      if (response.status === "success") {
      setNotificationType('success');
      setNotificationMessage(response.message);
      } else {
        setNotificationType('error');
        setNotificationMessage(response.message);
      }
      setTimeout(() => {
      fetchReplies();
    }, 2500);
    } catch (error) {
      setNotificationType('error');
      setNotificationMessage('Error deleting reply: ' + error.message);
    }
  }

  return (
    <div>
      <Row gutter={[0,16]}>
        <Col span={24}>        
        {loading ? (<div><Spin size="large" /></div>) : (repliesList.length > 0 )? (
        <List
            itemLayout="horizontal"
            dataSource={repliesList}
            renderItem={(item) => (
              <List.Item
              actions={
                item.user === currentUser ? 
                [<Popconfirm
                  description="Are you sure to delete your comment?"
                  onConfirm={ () => onDelete(item.timestamp) }
                  >
                  <Button type="primary">Delete</Button>
                </Popconfirm>
              ] : []
              }
            >
                <List.Item.Meta
                  avatar={<Avatar>{item.user[0]}</Avatar>}
                  title={
                    <Typography.Text>
                    <Typography.Text strong>{item.user}</Typography.Text>{' '}
                    <Typography.Text type="secondary">{new Date(item.timestamp).toISOString().slice(0, 19).replace("T", " ")}</Typography.Text>
                  </Typography.Text>
                  }
                  description={
                    <Typography.Paragraph>
                      { item.reply_msg }
                    </Typography.Paragraph>
                  }
                /> 
              </List.Item>
            )}
          />
        ) : (<></>)
        }
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col span={24} style={{ marginBottom: 16 }}>*/}
      {/*    {(notificationMessage) && (*/}
      {/*        <Alert*/}
      {/*            message={notificationMessage}*/}
      {/*            type={notificationType}*/}
      {/*            showIcon*/}
      {/*            closable*/}
      {/*            onClose={() => {setNotificationMessage(null); setNotificationType('')}}*/}
      {/*        />*/}
      {/*    )}*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Leave your comment" key="1">
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            clearOnDestroy={true}
          >
          <Row>
            <Col span={24}>
              <Form.Item name="reply_msg" rules={[
                {
                min: 1,
                message: 'Your comment must be at least 1 characters',
              },
                {
                  required: true,
                  message: 'Please leave your comment',
                },
              ]}>
                <Input.TextArea rows={8} showCount={true} maxLength="1000" style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-evenly">
            <Col>
              <Form.Item>
                  <Space>
                    <Popconfirm
                      description="Are you sure to submit your comment?"
                      onConfirm={form.submit}>
                      <Button type="primary">
                        Submit
                      </Button>
                    </Popconfirm>
                    <Button htmlType="button" onClick={onReset}>
                      Reset
                    </Button>
                  </Space>
              </Form.Item>
            </Col>
          </Row>
          </Form>
        </Collapse.Panel>
        </Collapse>       
        </Col>
      </Row>
    </div>
  );
};

const UserAnnotation = () => {
  const [stateTimelineData, setTimelineData] = useState([]);
  const [stateGlobalAnnotationData] = useAtom(atomAnnotationSearchSelected);

  useEffect(() => {
    logger.debug(stateGlobalAnnotationData);
    if (stateGlobalAnnotationData.length === 0) {
      setTimelineData([]);
    } else {
      let annotationTableData = stateGlobalAnnotationData.map((element, index) => {
        const annotationData = element.data;
        const annoTime = new Date(annotationData.time_stamp * 1000);
        return {
          children: <UserAnnotationCard element={annotationData} time={annoTime} />,
          key: element.id,
          time: annoTime,
        };
      });
      annotationTableData.sort((a, b) => {
        return b.time - a.time;
      });
      setTimelineData(annotationTableData);
    }
  }, [stateGlobalAnnotationData]);

  return (
    <>
      <div style={{ margin: '20px' }}>
        {stateTimelineData.length > 0 ? (
          <Timeline items={stateTimelineData} />
        ) : (
          <Empty description={'No user annotations.'} />
        )}
      </div>
    </>
  );
};

export default UserAnnotation;
