import {Col, Row} from 'antd';
import React, {useEffect, useState} from "react";
import {useAtom} from "jotai";
import VirtualTable from "../../../Library/VirtualTable";
import {
    atomGlobalExperiment,
    atomGlobalReferenceSpectrum,
    atomGlobalSpectrum,
    atomHighlightItem
} from "../../../Data/Atoms";
import ExperimentalLibraryResultData, {
    atomExperimentalLibrary,
    atomExperimentalLibrarySelectedTrigger,
    atomExperimentalLibraryTrigger
} from "./ExperimentalLibraryResultData";

import EIC from "./EICViewer";
import {createModuleLogger} from '../../../Library/logger';

const logger = createModuleLogger('ExperimentalLibraryResult');

const column = [
    {
        title: 'Study ID', dataIndex: 'study_id', key: 'study_id', ellipsis: false, width: 80,
        sorter: (a, b) => a.study_id.localeCompare(b.study_id),
    }, {
        title: 'File name', dataIndex: 'file_name', key: 'file_name', ellipsis: true, width: 280,
        sorter: (a, b) => a.file_name.localeCompare(b.file_name),
    }
]

const ExperimentalLibraryResult = () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateExperimentalLibrary,] = useAtom(atomExperimentalLibrary);
    const [, setExperimentalLibraryTrigger] = useAtom(atomExperimentalLibraryTrigger);
    const [, setExperimentalLibrarySelectedTrigger] = useAtom(atomExperimentalLibrarySelectedTrigger);
    const [stateHighlightItem, setHighlightItem] = useAtom(atomHighlightItem);

    const globalMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;

    // useEffect(() => {
    //     console.log("atomExperimentalLibrary", stateExperimentalLibrary);
    // }, [stateExperimentalLibrary]);

    ////////////////////////////////////////////////////////////////////////////////
    const [stateSearchResults, setSearchResults] = useState({});
    // Dealing with different search types
    useEffect(() => {
        const search_result = (stateGlobalSpectrum.analysis || {}).experimental_library;
        if (search_result && search_result.similar_spectra) {
            const chromMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;
            setExperimentalLibraryTrigger({method_id: chromMethodId, id_list: search_result.similar_spectra});
        }
    }, [stateGlobalSpectrum.analysis, stateGlobalRunData.metadata]);

    ////////////////////////////////////////////////////////////////////////////////
    const [stateGlobalReferenceSpectrumData,] = useAtom(atomGlobalReferenceSpectrum);

    return <>
        <ExperimentalLibraryResultData/>
        <EIC/>
        <Row>
            <Col span={24}>
                <VirtualTable
                    vid={"experimental-library-result-table"}
                    onRow={record => {
                        return {
                            onClick: event => {
                                setHighlightItem({type: "experimental_library", id: record.uniq_scan});
                                setExperimentalLibrarySelectedTrigger({
                                    method_id: globalMethodId,
                                    study_id: record.study_id,
                                    file_name: record.file_name,
                                    scan: record.uniq_scan,
                                })
                            }
                        }
                    }}
                    rowClassName={record => {
                        return stateHighlightItem.type === "experimental_library" && (stateHighlightItem.id === record.uniq_scan) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateExperimentalLibrary}/>
            </Col>
        </Row>
    </>;
};

export default ExperimentalLibraryResult;
