import 'antd/dist/reset.css';
import './App.css';
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {notification} from 'antd';
import {CookiesProvider} from 'react-cookie';
import { logger } from './Library/logger';

import Template from "./Pages/Global/Template";
import SpectrumMain from "./Pages/Spectrum/Main";
import BinBaseLibrary from "./Pages/Binbase/BinBaseLibrary";
import MainPage from "./Pages/MainPage/MainPage";
import UploadPage from "./Pages/Upload/UploadMain";
import ProcessingPage from "./Pages/StatusPage/ProcessingPage";
import UploadSpectrum from "./Pages/UploadSpectrum/Main";

function App() {
    notification.config({
        placement: 'top',
        duration: 5,
        rtl: false,
    });
    logger.info('Application started.')

    return (
        <div className="App">
            <>
                <CookiesProvider defaultSetOptions={{path: '/'}}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/">
                                <Route index element={<Template><MainPage/></Template>}/>

                                <Route path="_upload" element={<Template><UploadPage/></Template>}/>
                                <Route path="_upload_spectrum" element={<Template><UploadSpectrum/></Template>}/>
                                <Route path="_binbase" element={<Template><BinBaseLibrary/></Template>}/>
                                <Route path="_processing" element={<Template><ProcessingPage/></Template>}/>

                                <Route path=":wikiId">
                                    <Route index element={<Template><SpectrumMain/></Template>}/>
                                    <Route path="*" element={<Template><SpectrumMain/></Template>}/>
                                </Route>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </CookiesProvider>
            </>
        </div>
    );
}

export default App;
